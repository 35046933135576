<template>
    <div>
        <a-drawer :title="title" :width="650" :visible="drawerVisible" :body-style="{ paddingBottom: '80px' }" @close="handleDrawer(false)">
            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
                <a-row :gutter="16" class="flex-wrap">
                    <a-col v-if="cateId == 1" :span="12">
                        <a-form-item label="年级：" name="grade_id">
                            <a-select v-model:value="form.grade_id" placeholder="请选择年级" @change="filterList">
                                <a-select-option v-for="(item,idx) in seletArray.grade" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col v-if="cateId == 4" :span="24">
                        <a-form-item label="分类：" name="cate_home_id">
                            <a-select v-model:value="form.cate_home_id" placeholder="请选择家庭教育分类" @change="filterList">
                                <a-select-option v-for="(item,idx) in Education" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col v-if="cateId == 1" :span="12">
                        <a-form-item label="学期：" name="term_id">
                            <a-select v-model:value="form.term_id" placeholder="请选择学期" @change="filterList">
                                <a-select-option v-for="(item,idx) in seletArray.term" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col v-if="cateId == 1" :span="12">
                        <a-form-item label="科目：" name="subject_id">
                            <a-select v-model:value="form.subject_id" placeholder="请选择科目" @change="filterList">
                                <a-select-option v-for="(item,idx) in seletArray.subject" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col v-if="cateId == 1" :span="12">
                        <a-form-item label="章节：" name="chapter_id">
                            <a-select v-model:value="form.chapter_id" placeholder="请选择章节" @change="knowFilterList">
                                <a-select-option v-for="(item,idx) in chapter" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                                <template v-if="form.grade_id && form.term_id && form.subject_id" #dropdownRender="{ menuNode: menu }">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0" />
                                    <div class="addText" @mousedown="e => e.preventDefault()" @click="handleAddDialog()">
                                        <icon-font type="icon-tianjia" class="" />
                                        添加章节
                                    </div>
                                </template>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col v-if="cateId == 1" :span="24">
                        <a-form-item label="知识点：" name="knowledge_id">
                            <a-select v-model:value="form.knowledge_id" placeholder="请选择知识点">
                                <a-select-option v-for="(item,idx) in knowledge" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                                <template v-if="form.grade_id && form.term_id && form.subject_id && form.chapter_id" #dropdownRender="{ menuNode: menu }">
                                    <v-nodes :vnodes="menu" />
                                    <a-divider style="margin: 4px 0" />
                                    <div class="addText" @mousedown="e => e.preventDefault()" @click="handleAddDialog('know')">
                                        <icon-font type="icon-tianjia" class="" />
                                        添加知识点
                                    </div>
                                </template>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-form-item label="视频名称：" name="name">
                    <a-input v-model:value="form.name" placeholder="请输入视频名称" />
                </a-form-item>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item label="观看次数：">
                            <a-input v-model:value="form.view_count_set" placeholder="请输入观看次数" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="点赞次数：">
                            <a-input v-model:value="form.like_count_set" placeholder="请输入点赞次数" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-form-item label="视频封面（尺寸400*300）：" name="cover">
                    <UploadImage file_type="video" :img="form.cover" @uploadImage="uploadImage">
                        <a-image v-if="form.cover" :width="100" :preview="false" :src="domain+form.cover" :fallback="domain+'/default.png'" style="border-radius:4px" />
                    </UploadImage>
                </a-form-item>
                <a-form-item label="上传视频（MP4格式）：" name="url">
                    <div class="flex flex-column">
                        <a-upload accept="video/mp4" :customRequest="uploadVideo" :show-upload-list="false" :before-upload="beforeUpload">
                            <a-button>
                                <upload-outlined></upload-outlined>
                                上传视频
                            </a-button>
                        </a-upload>
                    </div>
                    <a-progress v-if="uploadProgress != 0 && uploadProgress != 100" :percent="uploadProgress" strokeColor="#008685" size="small" status="active" />
                    <a-row class="flex flex-jc-sb flex-ai-c" v-if="form.url" style="background:#f7f7f7;padding:5px 10px;border-radous:4px;margin-top:10px">
                        <div>
                            <icon-font type="icon-lianjie" style="margin-right:5px" />
                            <span>{{form.url}}</span>
                        </div>
                        <icon-font type="icon-shanchu" @click="deleteVideo" />
                    </a-row>
                </a-form-item>
                <a-form-item label="视频简介：">
                    <a-textarea v-model:value="form.content" placeholder="请输入视频简介" :rows="4" />
                </a-form-item>
            </a-form>
            <div class="submitBtn">
                <a-space>
                    <a-button block @click="handleDrawer(false)">取消</a-button>
                    <a-button block type="primary" @click="handleSubmit">保存</a-button>
                </a-space>
            </div>
        </a-drawer>

        <!--添加章节-->
        <DialogAssembly ref="chapterDialogRef" title="添加章节" @handleConfirm="handleChapterConfirm" @handleCancle="handleDialogCancle('chapter')">
            <a-form ref="chapterRef" :model="chapterForm" layout="vertical">
                <a-form-item label="章节名称" name="name">
                    <a-input v-model:value="chapterForm.name" placeholder="请输入章节名称" />
                </a-form-item>
            </a-form>
        </DialogAssembly>

        <!--添加知识点-->
        <DialogAssembly ref="knowDialogRef" title="添加知识点" @handleConfirm="handleKnowConfirm" @handleCancle="handleDialogCancle('know')">
            <a-form ref="knowRef" :model="knowForm" layout="vertical">
                <a-form-item label="知识点名称" name="name">
                    <a-input v-model:value="knowForm.name" placeholder="请输入知识点名称" />
                </a-form-item>
            </a-form>
        </DialogAssembly>
    </div>

</template>
<script>
import { PlusOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, toRefs, getCurrentInstance, onMounted } from 'vue';
import UploadImage from '@/components/UploadImage';
import DialogAssembly from '@/components/DialogAssembly';
import * as qiniu from 'qiniu-js';

export default defineComponent({
    name: 'VideoEdit',
    components: {
        LoadingOutlined,
        PlusOutlined,
        UploadOutlined,
        UploadImage,
        DialogAssembly,
        VNodes: (_, {
            attrs
        }) => {
            return attrs.vnodes;
        },
    },
    props: {
        seletArray: {
            type: Object
        },
        cateId: {
            type: String,
        }
    },

    setup(props, ctx) {
        const { proxy } = getCurrentInstance();
        // 抽屉开关
        const drawerVisible = ref(false);
        
        const chapterDialogRef = ref();
        const knowDialogRef = ref();
        //视频form
        const formRef = ref();
        //知识点form
        const knowRef = ref();
        //章节form
        const chapterRef = ref();

        const initData = reactive({
            form: {},//添加视频
            chapterForm: {},//快捷添加章节数据
            knowForm: {},//快捷添加知识点数据
            chapter: [],//章节下拉列表
            knowledge: [],//知识点下拉列表
            Education: [],//家庭教育分类下拉列表
            UploadConfig: {},//七牛云配置

            rules: {
                name: [{
                    required: true,
                    message: '请输入视频名称',
                }],
                cate_id: [{
                    required: true,
                    message: '请选择学科类别',
                }],
                cate_home_id: [{
                    required: true,
                    message: '请选择家庭教育分类',
                }],
                grade_id: [{
                    required: true,
                    message: '请选择年级',
                }],
                term_id: [{
                    required: true,
                    message: '请选择学期',
                }],
                subject_id: [{
                    required: true,
                    message: '请选择科目',
                }],
                chapter_id: [{
                    required: true,
                    message: '请选择章节',
                }],
                knowledge_id: [{
                    required: true,
                    message: '请选择知识点',
                }],
                cover: [{
                    required: true,
                    message: '请选择视频封面'
                }],
                url: [{
                    required: true,
                    message: '请选择视频'
                }],
            },

            title: '添加视频',
            domain: proxy.domain,
            Token: '',//上传视频token值
            uploadProgress: 0,//上传视频进度条
        });
        const getToken = () => {
            proxy.http.post('/admin.qiNiu/getConfig').then(response => {
                initData.UploadConfig = response;
            })
        }
        //年级班级科目筛选调取对应章节
        const filterList = () => {
            initData.form.chapter_id = undefined;
            initData.form.knowledge_id = undefined;
            initData.knowledge = [];
            ChapterList();
        }

        //章节选择调取对应知识点列表
        const knowFilterList = () => {
            initData.form.knowledge_id = undefined;
            knowledgeList();
        }

        const EducationList = () => {
            proxy.http.post('/admin.cateHome/index').then(response => {
                initData.Education = response;
            })
        };

        //获取章节列表
        const ChapterList = () => {
            //若类型、年级、学期、科目为空则禁止执行接口
            if (!(props.cateId && initData.form.grade_id && initData.form.subject_id && initData.form.term_id)) return;
            let submitform = {};

            //获取章节数据
            proxy.http.post('/admin.chapter/indexAll', {
                cate_id: props.cateId,
                grade_id: initData.form.grade_id,
                subject_id: initData.form.subject_id,
                term_id: initData.form.term_id,
            }, false).then(response => {
                initData.chapter = response;
            })
        };

        const knowledgeList = () => {
            //若类型、年级、学期、科目为空则禁止执行接口
            if (!(props.cateId && initData.form.grade_id && initData.form.subject_id && initData.form.term_id && initData.form.chapter_id)) return;
            //获取章节数据
            proxy.http.post('/admin.knowledge/indexAll', {
                cate_id: props.cateId,
                grade_id: initData.form.grade_id,
                subject_id: initData.form.subject_id,
                term_id: initData.form.term_id,
                chapter_id: initData.form.chapter_id,
            }, false).then(response => {
                initData.knowledge = response;
            })
        };


        //上传图片
        const uploadImage = (event) => {
            formRef.value.clearValidate()
            initData.form.cover = event;
        };

        //上传文件限制
        const beforeUpload = file => {
            const isJpgOrPng = file.type === 'video/mp4';
            if (!isJpgOrPng) {
                proxy.message.error('仅支持mp4格式！');
            }
            return isJpgOrPng;
        };

        //上传视频
        const uploadVideo = (event) => {
            initData.load = true;
            let key = new Date().getTime() + event.file.name; //上传后返回的文件名，为避免重复，加上了时间戳
            var config = {
                useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                region: qiniu.region.z1, //选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域。  华北区
                domain: initData.UploadConfig.domain, //配置好的七牛云域名  如   https://cdn.qniyun.com/
                chunkSize: 100, //每个分片的大小，单位mb，默认值3
                forceDirect: false //直传还是断点续传方式，true为直传
            };
            var putExtra = {};
            var observable = qiniu.upload(event.file, key, initData.UploadConfig.token, putExtra, config);
            observable.subscribe({
                //主要用来展示进度
                next: (event) => {
                    initData.uploadProgress = Number(parseFloat(Number(event.total.percent)).toFixed(0))
                },
                //上传错误后触发
                error: (err) => {
                    console.log(err)
                    getToken();
                },
                //上传成功后触发，返回文件地址。
                complete: (result) => {
                    formRef.value.clearValidate()
                    initData.form.url = result.key;
                }
            });
        };

        //删除替换视频
        const deleteVideo = () => {
            proxy.http.post('/admin.video/deleteQiNiu', {
                file_name: initData.form.url
            }, false).then(() => {
                initData.form.url = '';
            })
        }

        //抽屉弹框状态
        const handleDrawer = (states) => {
            drawerVisible.value = states;
            if (!drawerVisible.value) formRef.value.resetFields();
        };

        // 保存
        const handleSubmit = () => {
            formRef.value.validate().then(() => {
                Object.assign(initData.form, { cate_id: props.cateId });
                proxy.http.post('/admin.video/save', initData.form).then((re) => {
                    ctx.emit('success')
                    proxy.message.success('提交成功');
                    handleDrawer(false)
                })
            }).catch(error => { });
        }
        
        /*******************************************快捷添加章节知识点******************************************* */
        //添加章节
        const handleAddDialog = (state) => {
            if (state) knowDialogRef.value.handleOpen();
            else chapterDialogRef.value.handleOpen();
        };

        //章节保存
        const handleChapterConfirm = () => {
            if (!initData.chapterForm.name) return proxy.message.warning('请输入章节名称');
            let {
                grade_id,
                term_id,
                subject_id,
                cate_id
            } = initData.form;
            //赋值
            Object.assign(initData.chapterForm, { grade_id, term_id, subject_id, cate_id });

            proxy.http.post('/admin.chapter/save', initData.chapterForm).then(resposne => {
                initData.form.chapter_id = Number(resposne.id);
                proxy.message.success('提交成功');
                chapterDialogRef.value.handleCancel();
                ChapterList();
            })
        };

        //知识点保存
        const handleKnowConfirm = () => {
            if (!initData.knowForm.name) return proxy.message.warning('请输入知识点名称');
            let { 
                grade_id, 
                term_id, 
                subject_id, 
                cate_id, 
                chapter_id 
            } = initData.form;

            Object.assign(initData.knowForm, { grade_id, term_id, subject_id, cate_id, chapter_id });

            proxy.http.post('/admin.knowledge/save', initData.knowForm).then(resposne => {
                initData.form.knowledge_id = Number(resposne.id);
                proxy.message.success('提交成功');
                knowDialogRef.value.handleCancel();
                knowledgeList();
            })
        };


        const handleDialogCancle = (state) => {
            if (state == 'chapter') chapterRef.value.resetFields();
            else knowRef.value.resetFields();
        };

        /******************************************* 结束 ******************************************* */

        return {
            ...toRefs(initData),
            handleAddDialog,
            formRef,//添加视频form
            knowRef,//添加章节form
            chapterRef,//添加知识点form
            chapterDialogRef,//快捷添加章节弹框状态
            knowDialogRef,//快捷添加知识点弹框状态
            EducationList,//家庭教育分类列表
            ChapterList,//章节列表
            knowledgeList,//知识点列表
            knowFilterList,
            filterList,
            getToken,
            drawerVisible,
            handleDrawer,
            handleSubmit,
            uploadVideo,//上传视频
            deleteVideo, //删除视频
            uploadImage,//上传封面图
            beforeUpload,//视频上传前判断类型
            handleDialogCancle,//快捷添加章节知识点
            handleChapterConfirm,
            handleKnowConfirm
        };
    },
});
</script>
<style lang="scss" scoped>
    .addText{
        padding: 4px 8px;
        color:#008685;
        font-weight:600;
        cursor: pointer;
        text-align:center;
    }
</style>

